<template>
  <big-board-viewer :type="enums.BIG_BOARD_TYPE.BigBoard.value"></big-board-viewer>
</template>

<script>
import BigBoardViewer from "../components/BigBoardViewer.vue";
import enums from "../../../plugins/enums";
export default {
  name: "big-board",
  data() {
    return {
      enums: enums,
    };
  },
  components: { BigBoardViewer },
};
</script>
